<template>
  <div>
    <el-container class="InfoCenter">
        <el-header>
            <el-row>
              <el-col :span="3">
                <div class="Avatar">
                    <div class="UserAvatar">
                        <img :src="usericon" >
                    </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="UserNickName">
                    {{nickname}}
                </div>
              </el-col>
            </el-row>
        </el-header>
        <el-main>
            <div class="InfoContent">
                <div class="GoalList">
                    <div class="ListTitle">学习目标列表</div>
                    <div class="List">
                        <div class="itemList"  v-for="(item,index) in usergoallList" :key="index">
                            <div class="itemIndex">{{index+1}}、</div>
                            <div class="itemInfo">{{item.goalName}}</div>
                            <div class="itemDate">{{item.createTime}}</div>
                        </div>
                    </div>
                    <div class="AddNewGoal">
                        <el-button size="mini" class="btn-AddGoal" @click="_AddNewGoalPanel()">创建新目标</el-button>
                    </div>
                </div>
                <div class="UserInfoShow">
                    <div class="UserInfoWallet">
                       <div class="WalletTitle">
                            账户钱包
                       </div>
                       <div class="WalletBody">
                            余额：<span>{{wallet}}￥</span>
                       </div>
                    </div>
                    <div class="UserTrade">
                        <div class="TradeTitle">
                            交易记录
                        </div>
                        <div class="TradeBody">
                            <div class="ItemInfo">
                                <div class="TradeTypeInfo">交易类型</div>
                                <div class="TradeNameInfo">产品名称</div>
                                <div class="TradeNumberInfo">消费金额</div>
                                <div class="TradeTimeInfo">消费时间</div>
                            </div>
                            <div class="Item" v-for="(item,index) in tradelist" :key="index">
                                <div class="TradeType">{{item.type}}</div>
                                <div class="TradeName" :title="item.productName">{{item.productName}}</div>
                                <div class="TradeNumber">{{item.expense}}</div>
                                <div class="TradeTime" :title="item.tradeTime">{{item.tradeTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ClockIn">

            </div>   
        </el-main>
        <div class="AddGoal-wrapper" v-show="NewGoalPanel">
            <div class="AddGoalContent">
                <div class="AddGoalTitle">创建目标</div>
                <div class="GoalEdit">
                    <el-input v-model="GoalText" placeholder="请输入内容" class="edititem"></el-input>
                </div>
                <div class="Btn_Goal">
                    <el-button type="success" size="small" class="btn-com" @click="_AddNewGoal()">确定</el-button>
                    <el-button type="danger" size="small" class="btn-can" @click="CancelAdd()">取消</el-button>
                </div>
            </div>
        </div>
    </el-container>
  </div>
</template>

<script>
import {GetUserNickName,GetUserTrade,GetUserWallet} from "@/api/user.js"
import {AddNewGoal,GetGoalList} from '@/api/goal.js'
// import '@/assets/rem.js'
export default {
    name:'AccountCenter',
    data(){
        return{
            nickname:'',
            usericon:'',
            NewGoalPanel:false,
            GoalText:'',
            usergoallList:'',

            wallet:'',

            tradelist:[],
            tradetype:''
        }
    },
    created(){
        GetUserNickName().then(res=>{
                this.nickname = res.data.nickname
                this.usericon = res.data.icon
        }),
        this._GetGoalList()
        
        this._GetUserTradeInfo()
        this._GetUserWalletInfo()
    },
    mounted(){
        // setInterval(()=>{
        //     console.log('111')
        // },1000)
    },
    methods:{
        //用户钱包账户相关信息
        async _GetUserWalletInfo(){
           let res = await GetUserWallet()
           this.wallet = res.data.amount
        },
        // 用户所有交易账单信息
        async _GetUserTradeInfo(){
            let res = await GetUserTrade()
            this.tradelist = res.data.list 
            // this.tradelist.reverse()
            this.tradelist.forEach((item,index,arr) => {
                if(item.type == 0){
                    arr[index].type = '收入'
                }else{
                    arr[index].type = '支出'
                }
            });           
        },
        CancelAdd(){
            this.NewGoalPanel = false
            this.GoalText = ''
        },
        _AddNewGoalPanel(){
            this.NewGoalPanel = true
        },
        _AddNewGoal(){
            if(this.GoalText){
                let goalData = {
                    goalName:this.GoalText,

                }
                AddNewGoal(goalData).then(res=>{
                    console.log(res)
                    if(res.code == 0){
                        this.$message({
                            message: '创建成功',
                            type: 'success'
                        });
                        this.NewGoalPanel = false
                        this.GoalText = ''
                        this._GetGoalList()
                    } 
                })
            }else{
                this.$message({
                    message: '请输入有效的名称',
                    type: 'warning'
                });
            }
            
            
        },
        _GetGoalList(){
            GetGoalList().then(res=>{
                this.usergoallList = res.data.list
                // this.usergoallList.reverse()
            })
        }  
    }
}
</script>

<style scoped>
.InfoCenter{
    position: relative;
}
.Avatar{
    position: relative;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.UserAvatar{
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 10;
}
.UserAvatar img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}
.UserNickName{
    line-height: 60px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: white;
}
.InfoContent{
    width: 100%;
    height: 300px;
    padding: 30px 0;
    background-color: rgb(255 255 255 / 50%);
    display: flex;
    border-radius: 4px 4px 0px 0px;
}
.GoalList{
    position: relative;
    flex:  0 0 40%;
    height: 100%;
    background-color: rgb(249 249 249);
    border: 1px solid #cbcbcb;
    border-radius: 4px;
}
.ListTitle{
    width: 100%;
    height: 10%;
    line-height: 30px;
    font-size: 14px;
    background-color: black;
    color: white;
    border-radius: 4px 4px 0px 0px;
}
.List{
    padding: 5px;
    height: 70%;
    overflow: auto;
    border-bottom: 1px solid #cbcbcb;
}
.List::-webkit-scrollbar{
    display: none;
}
.itemList{
    display: flex;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    padding: 5px;
    text-align: left;
    background-color: #a1a1a1;
    margin-bottom: 5px;
    border-radius: 4px;
    color: white;
}
.itemIndex{
    flex: 0 0 15%;
}
.itemInfo{
    flex: 0 0 55%;
    overflow:hidden;
    text-overflow:ellipsis;
}
.itemDate{
    flex: 0 0 30%;
    font-size: 14px;
    line-height: 20px;
    overflow:hidden;
    text-overflow:ellipsis;
}
.AddNewGoal{
    width: 100%;
    height: 20%;
}
.btn-AddGoal{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.UserInfoShow{
    padding: 0 5px;
    flex:  1;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: end;
}
.UserInfoWallet{
    flex: 0 0 40%;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    background-color: rgb(249 249 249);
    /* border: 1px solid #cbcbcb; */
    border-left: 1px solid #cbcbcb;
    border-top: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
}
.WalletTitle{
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: black;
    color: white;
    border-radius: 4px 0px 0px 0px;
}
.WalletBody{
    padding-left: 10px;
    font-size: 18px;
    text-align: left;
}
.UserTrade{
    flex: 0 0 60%;
    height: 100%;
    border-radius: 0px 4px 4px 0px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #cbcbcb;
}

.TradeTitle{
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    background-color: black;
    color: white;
    border-radius: 0px 4px 0px 0px;
}
.TradeBody{
    /* width: 100%; */
    height: 270px;
    overflow: auto;
    padding: 0px 5px;
}
.TradeBody::-webkit-scrollbar{
    display: none;
}
.ItemInfo{
    position: sticky;
    top: 0;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #6c6c6c;
    color: white;
}
.TradeTypeInfo{
    flex: 1;
    font-size: 14px;
}
.TradeNameInfo{
    flex: 1;
    font-size: 14px;
}
.TradeNumberInfo{
    flex: 1;
    font-size: 14px;
}
.TradeTimeInfo{
    flex: 1;
    font-size: 14px;
}

.Item{
    width: 100%;
    height: 30px;
    margin-top: 5px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    background-color: #a1a1a1;
    color: white;
}
.TradeType{
    flex: 1;
    font-size: 14px;
    cursor: default;
}
.TradeName{
    flex: 1;
    font-size: 14px;
    white-space: nowrap;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TradeNumber{
    flex: 1;
    font-size: 14px;
    cursor: default;
}
.TradeTime{
    flex: 1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.ClockIn{
    width: 100%;
    padding: 30px 0;
    height: 300px;
    border-radius: 0px 0px 4px 4px;
    background-color: rgb(249 249 249 / 50%);
}

.AddGoal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    overflow: hidden;
    background-color: rgba(202, 202, 202, 0.523);
    z-index: 500;
    /* display: none; */
}
.AddGoalContent{
    position: absolute;
    top: 30%;
    left: 50%;
    width: 500px;
    height: 200px;
    margin-left: -250px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}
.AddGoalTitle{
    font-size: 18px;
    height: 15%;
    line-height: 30px;
}
.GoalEdit{
    position: relative;
    height: 60%;
    /* background-color: aquamarine; */
    padding: 0 30px;
}
.edititem{
    position: absolute;
    width: 450px;
    top: 40px;
    display: block;
}
.Btn_Goal{
    height: 15%;
    display: flex;
    align-items: center;
}
.btn-com{
    position: absolute;
    right: 20px;
}
.btn-can{
    position: absolute;
    left: 20px;
}
</style>